import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioInterface } from 'src/app/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as S3 from 'aws-sdk/clients/s3';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from 'src/app/services/util.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import ciudades from "src/assets/json/ciudades-colombia.json";
@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.scss']
})
export class MisDatosComponent implements OnInit {
  /** Lista de ciudades */
  ciudades: any[] = ciudades;
  /** observable de los ajustes del usuario */
  @Input() public usuario: Observable<UsuarioInterface>;
  /** observable de los ajustes del usuario */
  @Input() public puntos: Observable<any>;
  /** usuario para  la vista*/
  user: UsuarioInterface;

  /** verificar que se obtienen los datos del usuario */
  cargado = false;

  /** Verifica si esta en la pantalla de edicion o muestra de informacion */
  editar = false;

  /** Objeto para control de datos del formulario de registro */
  public frmEdit: FormGroup;

  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;

  /** Variable para determinar el tipo de usuario a registrar */
  public tipo = '';

  /** Variable de control para el loading */
  loading: any;

  /** Opciones de cargo en tipo de usuario Ferretero */
  public optFerretero = ['Dueño', 'Administrador', 'Vendedor'];

  /** Opciones de cargo en tipo de usuario Proveedor */
  public optProveedor = ['Dueño', 'Marketing', 'Ventas'];

  /** Emisor para actualizar menu superior en el home */
  @Output() public modificado = new EventEmitter<boolean>();
  /** Emisor para abrir modal de cambio de contraseña */
  @Output() public cambiarClave = new EventEmitter<boolean>();
  /** Imagen seleccionada para cambiar */
  selectedFile: any;
  /** Parametros de conexion a S3 para guardar la imagen */
  bucket: S3 = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1'
  });
  /** Foto */
  previewE: any = null;
  contenidoFileE: any;
  /** Modelo para obtener la geoRefencia del usuario */
  geoReferencia: any;
  /** Coordenadas del usuario */
  coordenadas: any;
  // Verificar cambio de foto
  timeStamp: any;
  /** Puntos del mes */
  puntosMes = 0;
  /** Controla loading de guardar datos */
  guardando = false;
  /** variable de busquede en el objeto de ciudades */
  keyword = 'ciudad';
  /** Modelo para la ubicacion del usuario */
  ubicacionC = '';
  constructor(
    public fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId,
    public authService: AuthService,
    public mainService: MainService,
    public utilService: UtilService
  ) {
    this.frmEdit = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      ferreteria: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
      cargo: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.usuario.subscribe(data => {
      this.user = data;
      if (data !== undefined) {
        this.cargado = true;
      }
    });
    if (
      this.user.geoReferencia !== undefined &&
      this.user.geoReferencia !== null &&
      this.user.geoReferencia !== ''
    ) {
    } else {
      this.setCurrentLocation();
    }
    this.puntos.subscribe(data => {
      this.puntosMes = data;
    });
  }
  /**
   * Funcion para abreviar la obtencion de valores de campos del formulario
   */
  get f() {
    return this.frmEdit.controls;
  }
  private setCurrentLocation() {
    if (isPlatformBrowser(this.platformId)) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          this.coordenadas = position.coords;
          let coord =
            this.coordenadas.latitude + ',' + this.coordenadas.longitude;
          this.mainService.getUbicacionCoordenadas(coord).subscribe(resp => {
            //@ts-ignore
            let data = JSON.parse(resp._body);
            this.geoReferencia = {
              lat: this.coordenadas.latitude,
              lng: this.coordenadas.longitude,
              direccion: data.results[0]
            };
          });
        });
      }
    }
  }
  /**
   * Funcion para determinar el tipo de usuario a registrar
   * @param param0 Tipo de usuario a registrar
   */
  seleccionaTipo({ tipo }) {
    this.tipo = tipo;
  }
  /**
   * Funcion que valida y envia al registro la informacion del formulario
   */
  onUpdate() {
    this.submited = true;
    if (this.frmEdit.invalid) {
      return false;
    }
    if (this.geoReferencia) {
      this.user.geoReferencia = this.geoReferencia;
    }
    let usuario = this.user;
    //@ts-ignore
    if (usuario.ubicacion.ciudad) {
      //@ts-ignore
      usuario.ubicacion= usuario.ubicacion.ciudad;
    }

    this.mainService.put('api/user/' + this.user._id, usuario).subscribe(
      (data: any) => {
        if (!data.success) {
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: 'Alerta',
            msj: data.msg,
            time: 7000
          });
        } else {
          this.utilService.stopLoading();
          this.utilService.showSuccess({
            title: 'Notificación',
            msj: 'Se modifico con éxito',
            time: 7000
          });
          this.modificado.emit(true);
          this.editar = false;
        }
      },
      err => {
        this.utilService.stopLoading();
        this.utilService.showWarning({
          title: 'Alerta',
          msj: 'Error al editar usuario. Intentalo de nuevo',
          time: 5000
        });
      }
    );
  }
  /**
   * Metodo para guardar las imagenes tanto al editar como al crear
   * @param tipo determina si la imagen es para editar o crear la noticia
   * @param pos Posicion dentro del arreglo que almacena las imagenes tanto en el crear como en el editar
   */
  guardar() {
    const usuario = this.frmEdit.value;
    this.submited = true;
    if (this.frmEdit.invalid) {
      return false;
    }
    this.utilService.startLoading();
    let idImagen = 'usuario-' + this.user._id;
    let imagen = this.contenidoFileE;
    let self = this;
    if (imagen !== undefined) {
      let file = imagen;
      let params = {
        Bucket: 'yoferretero-recursos',
        ACL: 'public-read',
        Key: idImagen,
        ContentType: file.type,
        Body: file
      };
      let options = {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      };
      let uploader = this.bucket.upload(params, options, function(err, data) {
        if (err) {
          self.utilService.showWarning({
            title: 'Error!',
            msj: 'Ocurrio un error por favor intenta de nuevo!',
            time: 5000
          });
          self.utilService.stopLoading();
          return false;
        }
        self.user.foto = data.Location.toString();
        self.onUpdate();
      });
      uploader.on('httpUploadProgress', function(event) {
        console.log((event.loaded * 100) / event.total);
      });
    } else {
      self.onUpdate();
    }
  }
  /**
   * Obtiene url de la foto del usuario
   */
  getLinkPicture() {
    if (this.timeStamp) {
      return this.user.foto + '?' + this.timeStamp;
    }
    return this.user.foto;
  }
  /**
   * Metodo para agregarle time al la url de la foto asi permitir que se actualize la imagen
   */
  setLinkPicture(url: string) {
    this.user.foto = url;
    this.timeStamp = new Date().getTime();
  }
  /** funciones para carga de icono edicion*/
  public abrirArchivoE() {
    if (isPlatformBrowser(this.platformId)) {
    const element: HTMLElement = document.getElementById(
      'img-segundariasE'
    ) as HTMLElement;
    element.click();
    }
  }
  /**
   * Metodo que permite obtener la informacion de la imagen a cargar y asignar una vista previa de la imagen al momento de editar
   * @param event Objeto de la imagen a editar
   */
  public inputImagenE(event) {
    this.contenidoFileE = event.target.files[0];
    let archivos = event.target.files;
    for (let archivo of archivos) {
      let reader = new FileReader();
      reader.onload = (img: any) => {
        this.previewE = img.target.result;
      };
      reader.readAsDataURL(archivo);
    }
  }
  onCambiarClave() {
    this.cambiarClave.emit(true);
  }
  /**
   * Dar el valor al modelo de edicion de ubicacion a
   * @param item Item seleccionado del objeto
   */
  selectEvent(item) {
    //this.ubicacionC = item.ciudad;
  }
}
