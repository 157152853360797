import { MainService } from './../../../services/main.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import ciudades from "src/assets/json/ciudades-colombia.json";
@Component({
  selector: 'modal-crear',
  templateUrl: './modal-crear.component.html',
  styleUrls: ['./modal-crear.component.css']
})
export class ModalCrearComponent implements OnInit {
  /** Lista de ciudades */
  ciudades: any[] = ciudades;
  /** Emisor para cerrar modal de login */
  @Output() public cerrar = new EventEmitter<boolean>();
  /** Emisor para cerrar modal de login y abrir el de registro */
  @Output() public registro = new EventEmitter<boolean>();
  /** Variable para controlar barra de desplazamiento del asistente paso a paso */
  public step = 1;

  /** Objeto para control de datos del formulario de registro */
  public frmCrear: FormGroup;

  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;

  /** Variable para determinar el tipo de usuario a registrar */
  public tipo = '';

  /** Bandera usada para deteminar si se aceptaron los terminos y condiciones */
  public terminos = false;

  /** Bandera usada para mostrar u ocultar modal de terminos y condiciones */
  public modalTerminos = false;

  /** Variable de control para el loading */
  loading: any;

  /** Opciones de cargo en tipo de usuario Ferretero */
  public optFerretero = ['Dueño', 'Administrador', 'Vendedor'];

  /** Opciones de cargo en tipo de usuario Proveedor */
  public optProveedor = ['Dueño', 'Marketing', 'Ventas'];

  /** Variable de control para mostrar u ocultar contraseña */
  mostrar = false;

  /** Variable de control para definir el tipo de input en el campo de clave de usuario */
  tipoInput = 'password';
  /** Coordenadas del usuario */
  coordenadas: any;
  /** Modelo para obtener la geoRefencia del usuario */
  geoReferencia: any;
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** variable de busquede en el objeto de ciudades */
  keyword = 'ciudad';
  /** Modelo para la ubicacion del usuario */
  ubicacionC = '';
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public utilService: UtilService,
    public mainService: MainService
  ) {
    this.frmCrear = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      ferreteria: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
      cargo: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
    });
  }

  ngOnInit(): void {
    if (!this.usuarioLogueado) {
      this.setCurrentLocation();
    }
  }
  private setCurrentLocation() {
    if (isPlatformBrowser(this.platformId)) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          this.coordenadas = position.coords;
          let coord =
            this.coordenadas.latitude + ',' + this.coordenadas.longitude;
          this.mainService.getUbicacionCoordenadas(coord).subscribe(resp => {
            //@ts-ignore
            let data = JSON.parse(resp._body);
            this.geoReferencia = {
              lat: this.coordenadas.latitude,
              lng: this.coordenadas.longitude,
              direccion: data.results[0]
            };
          });
        });
      }
    }
  }
  /**
   * Funcion para abreviar la obtencion de valores de campos del formulario
   */
  get f() {
    return this.frmCrear.controls;
  }
  /**
   * Funcion para navegar entre los paso del registro
   * @param param0 Paso que se va mostrar
   */
  setStep({ step }) {
    this.step = step;
  }
  /**
   * cierra modal de registro
   */
  salir() {
    this.cerrar.emit(false);
  }
  /**
   * Metodo para cambiar estado de mostrar u ocultar input de clave del usuario
   */
  toogleIcono() {
    this.mostrar = !this.mostrar;
    this.tipoInput = 'password';
    if (this.mostrar) {
      this.tipoInput = 'text';
    }
  }
  /**
   * Cambia el valor del boolean de terminos y condiciones
   */
  aceptarTerminos() {
    this.terminos = !this.terminos;
  }
  /**
   * Funcion para mostrar modal de terminos y condiciones
   */
  modal() {
    this.modalTerminos = true;
  }
  /**
   * Funcion para determinar el tipo de usuario a registrar
   * @param param0 Tipo de usuario a registrar
   */
  seleccionaTipo({ tipo }) {
    this.tipo = tipo;
    this.step = 2;
  }
  /**
   * Funcion que valida y envia al registro la informacion del formulario
   */
  onCrearCuenta() {
    this.submited = true;
    if (this.frmCrear.invalid) {
      return false;
    }
    if (!this.terminos) {
      this.utilService.showWarning({
        title: 'Alerta',
        msj: 'Debe aceptar los términos y condiciones.',
        time: 5000
      });
      return false;
    }
    let usuario = this.frmCrear.value;
    usuario.tipo = this.tipo;
    usuario.foto = '';
    usuario.genero = '';
    usuario.puntos = 0;
    usuario.estado = 'activo';
    usuario.geoReferencia = this.geoReferencia;

    //@ts-ignore
    if (usuario.ubicacion.ciudad) {
      //@ts-ignore
      usuario.ubicacion= usuario.ubicacion.ciudad;
    }
    this.utilService.startLoading();
    this.authService.registerUser(usuario).subscribe(
      (data: any) => {
        if (!data.success) {
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: 'Alerta',
            msj: data.msg,
            time: 5000
          });
        } else {
          let login = { email: usuario.email, password: usuario.password };
          this.loginUser(login);
        }
      },
      err => {
        this.utilService.stopLoading();
        this.utilService.showWarning({
          title: 'Alerta',
          msj: 'Error al crear usuario. Intentalo de nuevo',
          time: 7000
        });
      }
    );
  }
  /**
   * Hacer login de usuario despues de crear cuenta
   */
  loginUser(login) {
    this.authService.authenticateUser(login).subscribe(
      (data: any) => {
        if (data) {
          if (data.errors || !data.success) {
            this.utilService.showWarning({
              title: 'Alerta',
              msj: data.msg,
              time: 5000
            });
            this.utilService.stopLoading();
          } else {
            if (data.user) {
              this.utilService.stopLoading();
              if (this.authService.storeUserData(data.token, data.user)) {
                this.utilService.stopLoading();
                this.utilService.showSuccess({
                  title: '¡Genial!',
                  msj: 'Se inicio sesión',
                  time: 5000
                });
                this.salir();
              }
            }
          }
        } else {
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: 'Alerta',
            msj: 'No se ha establecido una conexión con el servidor.',
            time: 5000
          });
        }
      },
      error => {
        this.utilService.stopLoading();
        console.log(error);
        alert('Ocurrio un error.');
      }
    );
  }

  /**
   * Dar el valor al modelo de edicion de ubicacion a
   * @param item Item seleccionado del objeto
   */
  selectEventC(item) {
    this.ubicacionC = item.ciudad;
  }
}
