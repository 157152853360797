<footer>
  <div class="footer-div">
    <div class="container mx-auto h-100">
      <div class="title-news">
        <div class="row title-news">
          <div class="col-4 col-sm-4 col-md-12 col-xl-12 my-auto"></div>
          <div class="col-4 col-sm-4 col-md-3 col-xl-3 my-auto">
            <img
              class="logo-header"
              src="/assets/img/logo-horizontal-blanco.png"
            />
          </div>
          <div class="col-12 col-sm-12 col-md-9 col-xl-9">
            <nav
              class="nav navbar-nav mx-auto navbar-dark h-100 navbar-expand-lg"
            >
              <div class="container-fluid my-auto">
                <div>
                  <ul class="navbar nav my-auto" routerLinkActive="active">
                    <li
                      routerLinkActive="button-padding mx-auto"
                      *ngFor="let menuItem of menuItems"
                      class="button-padding mx-auto"
                    >
                      <a
                        class="nav-link menu-item my-auto my-auto"
                        [routerLink]="[menuItem.url]"
                      >
                        <p class="menu-item my-auto">{{ menuItem.titulo }}</p>
                      </a>
                    </li>
                    <li
                      *ngIf="usuarioLogueado"
                      routerLinkActive="active-link"
                      class="button-padding mx-auto"
                    >
                      <a
                        class="nav-link menu-item my-auto"
                        [routerLink]="['/perfil']"
                      >
                        <p class="menu-item my-auto">Mi perfil</p>
                      </a>
                    </li>
                    <li class="button-padding mx-auto" *ngIf="!usuarioLogueado">
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="modalLogin = true"
                      >
                        <p class="button-text-orange my-auto">Ingresa</p>
                      </button>
                    </li>
                    <li class="button-padding mx-auto" *ngIf="!usuarioLogueado">
                      <button
                        type="button"
                        class="btn btn-outline-light"
                        (click)="modalCrear = true"
                      >
                        <p class="button-text-white my-auto">Registrate</p>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <hr class="line" />
      </div>
      <div class="title-news">
        <div class="row">
          <div class="col-12">
            <div class="texto-titulo">
              Suscribete para recibir novedades importantes
            </div>
            <div class="texto-autor label-length"></div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-xl-6 my-auto display-enviar">
            <input
              class="text-area"
              placeholder="Escribe aquí tu correo electronico"
              [(ngModel)]="email"
            />

            <button
              expand="block"
              type="button"
              class="boton-iniciar"
              (click)="onEnviar()"
            >
              Enviar
            </button>
          </div>
          <div class="col-12 col-sm-12 col-md-1 col-xl-2 my-auto"></div>
          <div class="col-12 col-sm-12 col-md-4 col-xl-4 my-auto mx-auto display-enviar">
            <a
              (click)="
                enlaceExterno(
                  'https://apps.apple.com/co/app/yo-ferretero/id1535539564'
                )
              "
            >
              <img src="/assets/img/footer-appstore.png" class="iconos-extras2" />
            </a>

            <a
              (click)="
                enlaceExterno(
                  'https://play.google.com/store/apps/details?id=co.imagineapps.yoferretero'
                )
              "
            >
              <img
                src="/assets/img/footer-playstore.png"
                class="iconos-extras2"
              />
            </a>
          </div>
        </div>
        <div class="row>">
          <a
            (click)="
              enlaceExterno(
                'https://www.facebook.com/Yo-Ferretero-111701770647943'
              )
            "
          >
            <img src="/assets/img/footer-facebook.png" class="iconos-extras" />
          </a>
          <a
            (click)="
              enlaceExterno('https://www.instagram.com/yoferretero/?hl=en')
            "
          >
            <img src="/assets/img/footer-instagram.png" class="iconos-extras" />
          </a>
        </div>
      </div>

    </div>
  </div>
</footer>
