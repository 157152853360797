import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public logueado = new BehaviorSubject(false);
  public authToken: any;
  public user: any;
  //public headers: Headers;
  public headers: HttpHeaders;
  public baseUrl: string = 'https://develop-yoferretero-admin.imagineapps.co/';
  // public baseUrl: string = "http://localhost:8081/";

  constructor(@Inject(PLATFORM_ID) private platformId, private http: HttpClient, private cookieService: CookieService) {

    /*this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');*/
    if (isPlatformBrowser(this.platformId)) {
      this.headers = new HttpHeaders({ "Content-Type": "application/json" });
      this.user = JSON.parse(localStorage.getItem('userYFweb')) || {};
      if (
        localStorage.userYFweb != undefined &&
        localStorage.userYFweb != null &&
        localStorage.userYFweb != 'null'
      ) {
        this.setLogueado(true);
      } else {
        this.setLogueado(false);
      }
    }
  }

  public authenticateUser(user) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.baseUrl + 'users/authenticate', JSON.stringify(user), {
        headers: this.headers
      })
    //.pipe(map(res => res.json()));
  }

  public registerUser(user) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.baseUrl + 'users/register', JSON.stringify(user), {
        headers: this.headers
      })
    //.pipe(map(res => res.json()));
  }

  public forgot(email) {
    const obj = email;
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.baseUrl + 'users/forgot', JSON.stringify(obj), {
        headers: this.headers
      })
    //.pipe(map(res => res.json()));
  }

  public change(email, password, passwordNew) {
    const obj = { email: email, password: password, passwordNew: passwordNew };
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.baseUrl + 'users/change', JSON.stringify(obj), {
        headers: this.headers
      })
  }
  /**
   * Actualiza la contraseña de un usuario.
   * El usuario se identifica por el token que se envía al backend.
   * @param password la contraseña actual del usuario
   * @param passwordNueva la nueva contraseña que se quiere cambiar
   */
  public changePassword(data) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(`${this.baseUrl}users/change`, data, { headers: this.headers })
  }

  public reset(user) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.baseUrl + 'users/reset', JSON.stringify(user), {
        headers: this.headers
      })
  }

  public validateJwtExpired(JWT) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(`${this.baseUrl}users/validate-JWT-expired`, { JWT: JWT }, {
       headers: this.headers
       });
  }

  public storeUserData(token, user) {
    this.cookieService.set('tokenYFweb', token);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('tkYFweb', token);
      localStorage.setItem('userYFweb', JSON.stringify(user));
      this.authToken = token;
      this.user = user;
      this.setLogueado(true);
    }
    return true;
  }
  public loadToken() {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('tkYFweb');
      this.authToken = token;
    }
  }
  public logout() {
    this.setLogueado(false);
    this.authToken = null;
    this.cookieService.delete('tokenYFweb', '/');
    this.user = {};
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('userYFweb');
      localStorage.removeItem('tkYFweb');
    }
    return true;
  }
  public getToken() {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('tkYFweb');
      this.authToken = token;
    }
    return this.authToken;
  }
  public getUser() {
    return this.user;
  }
  setLogueado(logueado: boolean) {
    this.logueado.next(logueado);
  }
}
