<div class="seccion-noticias">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-xl-4">
      <a class="w-100" [routerLink]="['/noticias', item.slug]"  >
      <div
        class="noticia-secundaria"
        [ngStyle]="{ 'background-image': 'url(' + item.imagen + ')' }"
      ></div>
    </a>
    </div>
    <div class="col-12 col-sm-12 col-md-8 col-xl-8">
      <div class="row">
        <div class="col-12 ">
          <a class="w-100" [routerLink]="['/noticias', item.slug]"  >
          <h6 class="texto-titulo">
            {{ item.titulo | slice: 0:100 }}
          </h6>
          </a>
        </div>
        <div class="col-12 ">
          <a class="w-100" [routerLink]="['/noticias', item.slug]"  >

          <div style="display: none;" [id]="item._id" [innerHtml]="item.contenido"></div>
          <!--<p
            class="texto-info3"
          >{{obtenerParrafo()}}</p>-->
          </a>

        </div>
        <hr class="line position-bottom2" />
        <div class="col-8 col-sm-8 position-bottom">
          <div *ngIf="!usuarioLogueado" class="margen-acciones">
            <div class="likes">{{ item.likes + 40 | shortnumber }}</div>
            <img
              src="/assets/img/like-off.png"
              class="iconos-extras"
              alt="Debes iniciar sesión para dar like"
              (click)="toogleLike()"
            />
          </div>
          <div *ngIf="usuarioLogueado && usuario" class="margen-acciones">
            <div class="likes">{{ item.likes + 40| shortnumber }}</div>
            <img
              *ngIf="!like"
              src="/assets/img/like-off.png"
              class="iconos-extras"
              (click)="toogleLike()"
            />
            <img
              *ngIf="like"
              src="/assets/img/like-on.png"
              class="iconos-extras"
              (click)="toogleLike()"
            />
            <span class="ion ion-md-share font-20" (click)="modalCompartir = true"></span>
          </div>
        </div>
        <div class="col-4 col-sm-4  position-date">
          <div class="label-horas">{{ item.fechaPublicacion | datediff }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="modalCompartir">
  <div class="fondo-modal"></div>
  <div class="div-modal">
    <div class="row">
      <div class="col-12">
        <label class="titulo-modal float-r" (click)="modalCompartir = false"
          >X</label
        >
        <h6 class="texto-titulo2">
          Compartir
        </h6>
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="facebook"
            [url]="enlaceShared()"
            (click)="compartiendo()"
            class="btn btn-facebook"
          >
            <i class="fa fa-facebook"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="twitter"
            [url]="enlaceShared()"
            (click)="compartiendo()"
            style="color: white; background: #4AB3F4 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-twitter"
          >
            <i class="fa fa-twitter"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="linkedin"
            [url]="enlaceShared()"
            (click)="compartiendo()"
            style="color: white; background: #1178B3 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-linkedin"
          >
            <i class="fa fa-linkedin"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="whatsapp"
            [url]="enlaceShared()"
            (click)="compartiendo()"
            class="btn btn-whatsapp"
          >
            <i class="fa fa-whatsapp"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
