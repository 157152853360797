<div class="fondo-modal"></div>
<div class="div-modal">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r" (click)="salir()" >X</label>
    </div>
    <div class="col-sm-4 col-3"></div>
    <div class="col-sm-4 col-6">
      <img class="img-fluid" src="/assets/img/logo-login.png" />
    </div>
  </div>
  <form [formGroup]="frmOlvide" (ngSubmit)="onEnviar()" novalidate>
    <div class="row">

      <div class="col-12">
        <label class="texto-iniciar">¿Olvidaste tu contraseña?</label>
      </div>
      <div class="col-12">
        <label class="label-control texto-label">Escribe tu email para recuperar tu contraseña</label>
        <input
          class="form-control input-login"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submited && f.email.errors }"
          type="email"
          placeholder="Escribe aquí tu email"
        />
        <div *ngIf="submited && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email requerido</div>
          <div *ngIf="f.email.errors.email">Formato invalido</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="submit" class="btn-iniciar">Enviar</button>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.1)"
      size="medium"
      color="#fff"
      type="square-jelly-box"
      [fullScreen]="true"
    ><p class="texto-blanco">Verificando...</p></ngx-spinner
    >
  </form>
</div>

